/* eslint-disable */
type Null<T> = T | null | undefined;
export function formatError(err: any) {
	let details: {
		stack: Null<string>;
		name: Null<string>;
		message: Null<string>;
		code: Null<string>;
		string: Null<string>;
	} = {
		stack: null,
		name: null,
		message: null,
		code: null,
		string: null,
	};
	if (typeof err === 'string') {
		details.message = err;
		try {
			// Try to decode json if it's json.
			details.message = JSON.parse(details.message);
		} catch (e) {}
	} else if (typeof err === 'object') {
		details = {
			...details,
			stack: err.stack,
			name: err.name,
			message: err.message,
			code: err.code,
			string:
				typeof err.toString === 'function' &&
				err.toString !== Object.prototype.toString
					? err.toString()
					: null,
		};
	} else if (typeof err === 'function') {
		details.name = err.name;
	}

	if (details.message == null && details.string != null) {
		details.message = details.string;
	}

	if (
		details.stack != null &&
		details.message != null &&
		details.stack.indexOf(details.message) !== -1
	) {
		if (details.stack.indexOf(details.name + ': ' + details.message) === 0) {
			let lines = details.stack.split('\n');
			lines.shift();
			details.stack = lines.join('\n');
		}
	}

	return details;
}

export function getErrorMessage(err: any) {
	let details = formatError(err);

	if (details.message != null) {
		return details.message;
	} else if (details.stack != null) {
		return details.stack.split('\n')[0];
	} else if (details.name != null) {
		return details.name;
	} else {
		return 'unknown';
	}
}

export function errorToString(err: any, fallbackToJson = false): string {
	if (err instanceof Event) {
		return 'Generic Event of type ' + err.type;
	}
	const error = formatError(err);
	let header = '';
	let hasColin = false;
	if (error.name != null) {
		header += error.name;
	}
	if (error.code != null) {
		if (header !== '') {
			header += ' ';
		}
		header += '(' + error.code + ')';
	}

	if (error.message != null) {
		if (header !== '') {
			hasColin = true;
			header += ': ';
		}
		header += error.message;
	}

	let body = '';

	if (
		error.name != null &&
		error.message != null &&
		error.string != null &&
		error.string !== error.name + ': ' + error.message
	) {
		if (body !== '') {
			body += '\n';
		}
		body += error.string;
	}

	if (error.stack != null) {
		if (body !== '') {
			body += '\n';
		}
		body += error.stack;
	}

	if (body !== '' && header !== '') {
		return header + (!hasColin ? ':' : '') + '\n' + body;
	} else if (header !== '') {
		return header;
	} else if (body !== '') {
		return body;
	} else {
		if (fallbackToJson) {
			try {
				return JSON.stringify(error);
			} catch (e) {}
		}
		return 'Unable to format error.';
	}
}
