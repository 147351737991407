const config = {
	development: {
		apiUrl:
			process.env.NODE_ENV !== 'production'
				? `${window.location.origin}/api-dev`
				: '/api',
		googleAnalytics: {
			enabled: true,
			trackingId: 'G-4LC792G3XF',
		},
	},
	staging: {
		apiUrl:
			process.env.NODE_ENV !== 'production'
				? `${window.location.origin}/api-stage`
				: '/api',
		googleAnalytics: {
			enabled: true,
			trackingId: 'G-K5SRXR1K73',
		},
	},
	production: {
		apiUrl:
			process.env.NODE_ENV !== 'production'
				? `${window.location.origin}/api-prod`
				: `${window.location.origin}/api`,
		googleAnalytics: {
			enabled: true,
			trackingId: 'G-K5SRXR1K73',
		},
	},
} as const;

const publicPath =
	(process.env.NODE_ENV === 'production' ? process.env.PUBLIC_PATH : null) ??
	'/';
const publicBaseName = publicPath.replace(/\/$/, '');

const parsedPublicPath = new URL(publicPath, window.location.href).pathname;
const parsedPublicBaseName = parsedPublicPath.replace(/\/$/, '');

const result = {
	...config[process.env.ENVIRONMENT],
	publicPath,
	publicBaseName,
	parsedPublicBaseName,
	parsedPublicPath,
	rulesUrl: 'https://therulespage.com/xfinity-weallball',
	privacyPolicyUrl: 'https://www.xfinity.com/privacy/policy',
};

result.apiUrl = result.publicBaseName + result.apiUrl;

export default result;
