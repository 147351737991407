import Spinner from 'react-spinkit';

import { motion } from 'framer-motion';
import * as React from 'react';

import * as styles from './loading.module.scss';

export default function Loading(): JSX.Element {
	return (
		<motion.div className={styles.root} exit={{ opacity: 0 }}>
			<h1>Loading…</h1>
			<Spinner name="folding-cube" />
		</motion.div>
	);
}
