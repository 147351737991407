import _ from 'lodash';
import React, {
	DependencyList,
	MutableRefObject,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import { useMount, useMountedState, useUnmount } from 'react-use';

import Cancellable from '~src/utils/Cancellable';
import { safeHasOwnProperty } from '~src/utils/utils';

const Else = Symbol('Else');
type Else = typeof Else;

type KindOfBoolean = boolean | null | undefined;
type ConditionalArg = [KindOfBoolean, ...ArgsWithElse[]];
type Arg = (string | null | undefined) | ConditionalArg;
type Args = Arg | Arg[];
type ArgsWithElse = (Arg | Else) | Arg[];

function isConditionalArg(arg: ConditionalArg | Arg[]): arg is ConditionalArg {
	return typeof arg[0] === 'boolean';
}

function getCondition(args: ConditionalArg): [boolean, Arg[], Arg[]] {
	const mutableArgs = [...args] as ConditionalArg;
	const condition = mutableArgs.shift() as boolean;
	let trueValue: Arg[] = [];
	let falseValue: Arg[] = [];

	const elsePosition = mutableArgs.indexOf(Else);
	if (elsePosition !== -1) {
		trueValue = mutableArgs.slice(0, elsePosition) as Arg[];
		const possibleFalseValue = mutableArgs.slice(elsePosition + 1);
		if (possibleFalseValue.indexOf(Else) !== -1) {
			throw new Error(
				`Error in 'className', only one 'Else' can be given in a scope, got: ${JSON.stringify(
					args.map((a) => (a === Else ? 'Symbol.Else' : a)),
				)}.`,
			);
		}
		falseValue = possibleFalseValue as Arg[];
	} else {
		trueValue = mutableArgs as Arg[];
	}

	return [condition, trueValue, falseValue];
}

function reduceArg(args: Args): string[] {
	if (Array.isArray(args)) {
		if (isConditionalArg(args)) {
			const [condition, trueValue, falseValue] = getCondition(args);
			if (condition) {
				// Need to typecast because bad tuple support in TypeScript.
				return reduceArg(trueValue);
			} else {
				return reduceArg(falseValue);
			}
		} else {
			return args.reduce<string[]>((current, next) => {
				return [...current, ...reduceArg(next)];
			}, []);
		}
	} else if (typeof args === 'string') {
		return [args];
	} else {
		return [];
	}
}

/**
 * className
 *
 * Allows combining classNames. Has the added feature of conditional support.
 *
 * Returns an object that cna be combined with a react element:
 *   <MyElement {...className('a','b')} />
 *
 * Conditions must be in an array. First item is a boolean, rest is a class name.
 * You can supply a className.Else in the condition, anything after the Else
 * will be added if the condition was false.
 *
 * @example className('a','b') = 'a b'
 * @example className(['a','b']) = 'a b'
 * @example className([false,'a'],'b') = 'b'
 * @example className(['a',[false,'b']]) = 'a'
 * @example className(['a',[false,'b',className.Else,'c']]) = 'a c'
 * @example className('a',[false,'b','c',className.Else,'d','e',['f',[true,'g',className.Else,'h']]]) = 'a d e f g'
 */
export function className(...args: Args[]): string {
	return args
		.reduce<string[]>((current, next) => {
			return [...current, ...reduceArg(next)];
		}, [])
		.join(' ');
}

className.Else = Else;

export const Empty = Symbol.for('EMPTY');

function haveDependenciesChanged(
	previous: DependencyList,
	next: DependencyList,
): boolean {
	if (previous.length !== next.length) {
		return true;
	}

	return _.zip(previous, next).some(([a, b]) => a !== b);
}

/**
 * Just like React's useMemo, except that you can be guaranteed the value won't be
 * thrown away in newer versions of React.
 *
 */
export function useRealMemo<T>(
	factory: (oldValue: T | typeof Empty) => T,
	deps: DependencyList,
): T {
	const ref = useRef<{
		deps: DependencyList;
		value: T | typeof Empty;
	}>({
		deps,
		value: Empty,
	});
	const { current } = ref;

	if (current.value === Empty || haveDependenciesChanged(current.deps, deps)) {
		current.value = factory(current.value);
		current.deps = deps;
	}

	return current.value;
}

/**
 * This allows you to call an async function inside a react component and ensure
 * that if you get results back, the component is still mounted. The promise
 * will never resolve if the component is unmounted.
 *
 * Example:
 * const safeCallback = useSafeCallback(someFunction);
 *
 * const callback = useCallback(async () => {
 *     const value = await safeCallback('some','argument');
 *     setState(value);
 * },[safeCallback]);
 */
export function useSafeCallback<Params extends unknown[], Ret>(
	fn: (...args: Params) => Promise<Ret>,
): (...args: Params) => Promise<Ret> {
	const isMounted = useMountedState();

	return useCallback(
		(...args: Params) => {
			return new Promise<Ret>((fulfill, reject) => {
				fn(...args).then(
					(value) => {
						if (isMounted()) {
							fulfill(value);
						}
					},
					(value) => {
						if (isMounted()) {
							reject(value);
						}
					},
				);
			});
		},
		[fn, isMounted],
	);
}

export function usePromise(): <T>(promise: Promise<T>) => Promise<T> {
	const isMounted = useIsMounted();

	return useCallback(
		<T>(promise: Promise<T>): Promise<T> => {
			return new Promise((fulfill, reject) => {
				promise.then(
					(value) => {
						if (isMounted()) {
							fulfill(value);
						}
					},
					(value) => {
						if (isMounted()) {
							reject(value);
						}
					},
				);
			});
		},
		[isMounted],
	);
}

export function useIsMounted(): () => boolean {
	const isMounted = useRef(true);

	useEffect(() => {
		isMounted.current = true;
		return () => {
			isMounted.current = false;
		};
	}, []);

	return useCallback(() => {
		return isMounted.current;
	}, []);
}

/**
 * This allows you to call a function when the component unmounts.
 */
export function useOnUnmount(effect: VoidFunction): void {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const ref = useAlwaysUpToDateRef(effect);

	useEffect(() => {
		return () => {
			// eslint-disable-next-line react-hooks/exhaustive-deps
			ref.current();
		};
	}, [ref]);
}

/**
 * This allows you to call a function when the component mounts. The reason you'd do this
 * as opposed to using useEffect is because you have dependencies that update. There are
 * no dependencies list because your function will only be called once.
 */
export function useOnMount(effect: VoidFunction): void {
	useEffect(() => {
		effect();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
}

export function useScreenSize(): { width: number; height: number } {
	const [screenSize, setScreenSize] = useState({
		width: document.documentElement.clientWidth,
		height: document.documentElement.clientHeight,
	});

	useEffect(() => {
		const onResize = () =>
			setScreenSize({
				width: document.documentElement.clientWidth,
				height: document.documentElement.clientHeight,
			});
		window.addEventListener('resize', onResize);
		window.addEventListener('orientationchange', onResize);

		return () => {
			window.removeEventListener('resize', onResize);
			window.removeEventListener('orientationchange', onResize);
		};
	}, []);

	return screenSize;
}

export function useWindowSize(): { width: number; height: number } {
	const [screenSize, setScreenSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	useEffect(() => {
		const onResize = () =>
			setScreenSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		window.addEventListener('resize', onResize);
		window.addEventListener('orientationchange', onResize);

		return () => {
			window.removeEventListener('resize', onResize);
			window.removeEventListener('orientationchange', onResize);
		};
	}, []);

	return screenSize;
}

/**
 * This hook allows you to capture a ref and forward it onto another component.
 *
 * @param originalRef is the original ref that you want to wrap
 * @returns A ref object
 */
export function useForwardedRef<T>(
	originalRef?: MutableRefObject<T | null> | null,
): MutableRefObject<T | null> {
	const ref = useRef<T | null>(originalRef?.current ?? null);
	ref.current = originalRef?.current ?? null;

	const refWrapper = useRealMemo(() => {
		return {
			get current() {
				if (originalRef != null) {
					return originalRef.current;
				}
				return ref.current;
			},
			set current(value) {
				if (originalRef != null) {
					originalRef.current = value;
				}
				ref.current = value;
			},
		};
	}, []);

	return refWrapper;
}

type Setters =
	| 'toggle'
	| 'on'
	| 'off'
	| 'enable'
	| 'disable'
	| 'show'
	| 'hide'
	| 'open'
	| 'close'
	| 'setTrue'
	| 'setFalse';
type Values =
	| 'value'
	| 'isOn'
	| 'isOff'
	| 'isEnabled'
	| 'isDisabled'
	| 'isVisible'
	| 'isHidden'
	| 'isOpen'
	| 'isClosed';
type UseBooleanRet = Record<Setters, VoidFunction> &
	Record<Values, boolean> & {
		set: (value: boolean) => void;
	};

/**
 * This is a utility hook that exposes a boolean, and the ability to change
 * and inspect  its value using natural methods.
 *
 * Example:
 * ```ts
 * const myBoolean = useBoolean();
 *
 * return <Modal onClose={myBoolean.close} open={myBoolean.isOpen()} />
 * ```
 *
 * Possible inspections:
 *
 * - `value()`
 * - `isOn(), isOff()`
 * - `isEnabled(), isDisabled()`
 * - `isVisible(), isHidden()`
 * - `isOpen(), isClosed()`
 *
 * Possible modifiers:
 *
 * - `enable(), disable()`
 * - `show(), hide()`
 * - `open(), close()`
 *
 */
export function useBoolean(defaultValue = false): UseBooleanRet {
	const [state, set] = useState(defaultValue);

	const on = useCallback(() => {
		set(true);
	}, []);

	const off = useCallback(() => {
		set(false);
	}, []);

	const toggle = useCallback(() => {
		set((v) => !v);
	}, []);

	const isOn = state;
	const isOff = !isOn;

	return {
		value: state,
		set,
		on,
		off,
		toggle,
		isOn,
		isOff,
		isEnabled: isOn,
		isDisabled: isOff,
		isOpen: isOn,
		isClosed: isOff,
		isVisible: isOn,
		isHidden: isOff,
		enable: on,
		disable: off,
		show: on,
		hide: off,
		open: on,
		close: off,
		setTrue: on,
		setFalse: off,
	};
}

type FifoQueueController<T> = {
	push(item: T): void;
	shift(): T | undefined;
	first: T | undefined;
	last: T | undefined;
};

/**
 * This hook exposes a first-in-first-out queue. It differs from
 * react-use/useQueue in that the functions returned are stable and won't change
 * on re-renders.
 *
 * Returns an object with this type signature:
 *
 * ```ts
 * type Ret<T> = {
 *   push(item: T): void;
 *   shift(): T | undefined;
 *   first: T | undefined;
 *   last: T | undefined;
 * }
 * ```
 */
export function useFifoQueue<T>(
	defaultValue: T[] = [],
): FifoQueueController<T> {
	const [state, setState] = useState<T[]>(defaultValue);

	const ref = useRef(state);

	const controller = useRealMemo<FifoQueueController<T>>(
		() => ({
			push(item) {
				ref.current = [...ref.current, item];
				setState(ref.current);
			},
			shift() {
				if (ref.current.length === 0) {
					return undefined;
				}

				const [first, ...rest] = ref.current;
				ref.current = rest;
				setState(ref.current);

				return first;
			},
			get first() {
				return ref.current[0];
			},
			get last() {
				return ref.current[ref.current.length - 1];
			},
		}),
		[],
	);

	return controller;
}

export type UseAsyncFnState<V> = {
	loading: boolean;
} & (
	| {
			loaded: false;
			error: undefined | unknown;
			value: V | undefined;
	  }
	| {
			loaded: true;
			error: undefined;
			value: V;
	  }
);

type AsyncFn<Params extends unknown[], Ret> = {
	(...args: Params): Promise<Ret>;
	latest: (...args: Params) => Promise<Ret>;
};

/**
 * This hook will let you inspect the state of a promise callback. It returns a
 * state object and a wrapped callback that, when called, will update the
 * returned state with the state of the callback.
 *
 * If the returned callback is called prior to a previous callback completing,
 * the previous callback's state will be ignored. Meaning, only the latest
 * callback's state will be reflected by this hook. The promise will still
 * resolve to its caller.
 *
 * This hook is different from react-use's useAsyncFn in that this will tell you
 * if the callback has loaded or not, which is helpful if the result of the
 * promise is undefined. Also, this hook will let you retain the previous value
 * if an error occurs, enabled by setting the 3rd argument to `true`.
 *
 *
 * @param fn The callback to allow introspection of.
 * @param deps An array containing the dependencies of the callback
 *   If no dependencies are set, then the function itself is the dependency
 * @param retainValueOnError Set to true to keep the previous value on an error
 *
 * @returns A tuple following this type structure:
 *
 * ```ts
 * type Ret<Params,T> = [State<T>,(...args: Params) => Promise<T>];
 *
 * type State<T> = {
 * 	loading: boolean;
 * 	loaded: boolean;
 * 	error: unknown | undefined;
 * 	value: T | undefined;
 * }
 * ```
 */
export function useAsyncFn<Params extends unknown[], Ret>(
	fn: (...args: Params) => Promise<Ret>,
	deps: DependencyList = [],
	retainValueOnError = false,
): [UseAsyncFnState<Ret>, AsyncFn<Params, Ret>] {
	const [state, setState] = useState<UseAsyncFnState<Ret>>({
		loading: false,
		loaded: false,
		error: undefined,
		value: undefined,
	});

	const cancellableRef = useRef<Cancellable | undefined>();

	useUnmount(() => {
		cancellableRef.current?.cancel();
	});

	const callback = useCallback(
		(...args: Params): Promise<Ret> => {
			cancellableRef.current?.cancel();

			setState((previousState) => ({
				...previousState,
				loading: true,
			}));

			const cancellable = (cancellableRef.current = new Cancellable());

			const promise = fn(...args);

			cancellable.wrapPromise(promise).then(
				(value) =>
					setState({
						loading: false,
						loaded: true,
						error: undefined,
						value,
					}),
				(error) =>
					setState((currentState) => {
						if (currentState.loaded && retainValueOnError) {
							return {
								loading: false,
								loaded: true,
								value: currentState.value,
								error,
							};
						} else {
							return {
								loading: false,
								loaded: false,
								value: undefined,
								error,
							};
						}
					}),
			);

			return promise;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[...deps, ...(deps?.length > 0 ? [] : [fn]), retainValueOnError],
	);

	const ref = useRef(callback);
	ref.current = callback;

	// This ensures that every call callback.latest uses the latest function
	const latest = useCallback((...args: Params) => {
		return ref.current(...args);
	}, []);

	if (!safeHasOwnProperty(callback, 'latest')) {
		Object.defineProperty(callback, 'latest', {
			value: latest,
		});
	}

	return [state, callback as AsyncFn<Params, Ret>];
}

export type UseMountAsyncFnState<V> = {
	reload: VoidFunction;
} & (
	| {
			loading: true;
			loaded: false;
			error: undefined;
			value: undefined;
	  }
	| {
			loading: false;
			loaded: true;
			error: undefined;
			value: V;
	  }
	| {
			loading: false;
			loaded: false;
			error: string | number | boolean | symbol | bigint | object;
			value: undefined;
	  }
);

export function useMountAsyncFn<Ret>(
	fn: () => Promise<Ret>,
	deps: DependencyList = [],
): UseMountAsyncFnState<Ret> {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const [state, call] = useAsyncFn(fn, deps);
	const firstRender = useRef(true);
	// This is a little hack to make sure that loading is set to true on the first render
	if (firstRender.current) {
		state.loading = true;
		firstRender.current = false;
	}
	useMount(() => {
		call();
	});

	const stateWithReload = React.useMemo(() => {
		return {
			...state,
			reload: call,
		};
	}, [call, state]);

	return stateWithReload as UseMountAsyncFnState<Ret>;
}

export function useCache<T>(value: T, deps: DependencyList = []): T {
	const ref = useRef(value);
	useEffect(() => {
		ref.current = value;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps);
	return ref.current;
}

export function useStaticPromise<T>(promise: Promise<T>): UseAsyncFnState<T> {
	const callback = useCallback(() => promise, [promise]);

	const [state, fn] = useAsyncFn(callback);

	useEffect(() => {
		fn();
	}, [fn]);

	return state;
}

export function useRefProxy<T>(
	originalRef: null | React.RefCallback<T> | React.MutableRefObject<T>,
): React.MutableRefObject<T> {
	const ref = React.useRef<T>(
		typeof originalRef === 'function' ? null : originalRef?.current ?? null,
	) as React.MutableRefObject<T>;
	const refProxy = useRealMemo(() => {
		return {
			get current() {
				return ref.current;
			},
			set current(value) {
				ref.current = value;
				if (typeof originalRef === 'function') {
					originalRef(value as UnsafeAny);
				} else if (originalRef != null) {
					originalRef.current = value as UnsafeAny;
				}
			},
		};
	}, []);

	return refProxy as UnsafeAny;
}

// A useRef alternative that gives you mount and unmount hooks.
export function useElementRef<T>(
	onMount: (value: T) => void,
	onUnMount: (value: T) => void,
): MutableRefObject<T | null> & {
	mountedThisCycle: boolean;
} {
	const ref = useRef<T | null>(null);

	const onMountCallback = useRef(onMount);
	onMountCallback.current = onMount;

	const onUnMountCallback = useRef(onUnMount);
	onUnMountCallback.current = onUnMount;

	const currentRenderCycle = useRef(0);
	const cycleMountedOn = useRef(-1);

	currentRenderCycle.current += 1;

	const refWrapped = useMemo(() => {
		return {
			get current(): T | null {
				return ref.current;
			},
			set current(value: T | null) {
				const previous = ref.current;
				const next = value;

				ref.current = next;

				if (next == null && previous != null) {
					// Unmounting
					onUnMountCallback.current(previous);
				} else if (previous == null && next != null) {
					// mounting
					onMountCallback.current(next);
					cycleMountedOn.current = currentRenderCycle.current;
				}
			},
			get mountedThisCycle(): boolean {
				return cycleMountedOn.current === currentRenderCycle.current;
			},
		};
	}, []);

	return refWrapped;
}

export function useAlwaysUpToDateRef<T>(
	initialValue: T,
): React.MutableRefObject<T> {
	const ref = React.useRef(initialValue);
	ref.current = initialValue;

	return ref;
}

export function useStateWithRef<S = undefined>(
	initialState: S | (() => S),
): [S, React.Dispatch<React.SetStateAction<S>>, React.MutableRefObject<S>] {
	const state = React.useState<S>(initialState);

	return [state[0], state[1], useAlwaysUpToDateRef(state[0])];
}
