import isMobile from 'is-mobile';

import * as React from 'react';

import ErrorBoundary from '~src/domains/core/components/ErrorBoundary';
import Loading from '~src/domains/core/components/Loading';
import {
	initializeAnalytics,
	useAnalyticsSetAppType,
} from '~src/domains/core/lib/analytics';
import { query } from '~src/utils/utils';

const Desktop = React.lazy(() => import('./DesktopPage'));
const Mobile = React.lazy(() => import('./Mobile'));

initializeAnalytics();

export default function Main(): JSX.Element {
	const isMobileResult =
		React.useMemo(isMobile, []) ||
		// Allow overriding the mobile detection by passing a query param only in development
		(process.env.ENVIRONMENT !== 'production' && query.forceMobile === 'true');

	useAnalyticsSetAppType(isMobileResult ? 'mobile' : 'desktop');

	return (
		<ErrorBoundary>
			<React.Suspense fallback={<Loading />}>
				{isMobileResult ? <Mobile /> : <Desktop />}
			</React.Suspense>
		</ErrorBoundary>
	);
}
