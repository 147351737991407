import { motion } from 'framer-motion';
import * as React from 'react';

import * as styles from './button.module.scss';
import * as analytics from '~src/domains/core/lib/analytics';
import { className } from '~src/utils/react';

type Props = React.PropsWithChildren<{
	type?: 'submit' | 'reset' | 'button';
	disabled?: boolean;
	onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	href?: string;
	className?: JSX.IntrinsicElements['div']['className'];
	analyticsEvent?: string;
}> &
	(
		| {
				primary: true;
				secondary?: undefined;
				underline?: undefined;
				outline?: undefined;
		  }
		| {
				primary?: undefined;
				secondary: true;
				underline?: boolean;
				outline?: boolean;
		  }
		| {
				primary?: undefined;
				secondary?: undefined;
				underline?: undefined;
				outline?: undefined;
		  }
	);

const ButtonForwardRef = React.forwardRef<HTMLButtonElement, Props>(
	function Button(props: Props, ref): JSX.Element {
		const {
			type = 'button',
			primary,
			secondary,
			onClick,
			children,
			disabled,
			className: classNameProp,
			href,
			underline,
			outline,
			analyticsEvent,
		} = props;

		const aElm = React.useMemo(() => {
			if (href) {
				const a = document.createElement('a');
				a.target = '_blank';
				a.rel = 'noopener noreferrer';
				return a;
			}
		}, [href]);

		React.useEffect(() => {
			if (href != null && aElm != null) {
				aElm.href = href;
			}
		}, [href, aElm]);

		const onClickProxy = React.useCallback(
			(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
				if (href != null && aElm != null) {
					aElm.click();
				}
				onClick?.(e);
				if (analyticsEvent) {
					analytics.analyticsEvent(analyticsEvent);
				}
			},
			[href, aElm, onClick, analyticsEvent],
		);

		return (
			<button
				ref={ref}
				type={type}
				onClick={onClickProxy}
				className={className(
					styles.root,
					classNameProp,
					[primary === true, styles.primary],
					[secondary === true, styles.secondary],
					[secondary === true && underline === true, styles.underline],
					[secondary === true && outline === true, styles.outline],
				)}
				disabled={disabled}
			>
				{children}
			</button>
		);
	},
);

export default motion<Props>(ButtonForwardRef);
