import '~src/assets/index.scss';

import * as React from 'react';
import * as ReactDOM from 'react-dom';

import Main from '~src/domains/landing/Main';

ReactDOM.render(
	<React.StrictMode>
		<Main />
	</React.StrictMode>,
	document.getElementById('root'),
);
