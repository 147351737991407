@use 'src/assets/config.scss';

.root {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	font-family: config.$font;
	color: config.$white;

	h1 {
		font-weight: bold;
	}

	p {
		margin-top: 1rem;
	}

	button {
		margin-top: 1rem;
	}
}
