import { createHmac } from 'crypto';

function applyTranslationToString(str: string): string {
	const letters = 'abcdefghijklmnopqrstuvwxyz0123456789'.split('');
	let prev = 0;
	return str
		.split('')
		.map((part) => {
			const offset = (part.charCodeAt(0) + prev) % str.length;
			prev = offset;
			return letters[str.charCodeAt(offset) % letters.length];
		})
		.join('');
}

export default function generateSessionNonce(pt: string, t: string): string {
	const previousNonceTranslated = applyTranslationToString(pt);
	const nonceTranslated = applyTranslationToString(t);
	const secret = applyTranslationToString(
		`${previousNonceTranslated}${nonceTranslated}`,
	);
	const hash = createHmac('ripemd160', secret)
		.update(`${previousNonceTranslated}${nonceTranslated}`)
		.digest('hex')
		.substr(0, 32)
		.toUpperCase();

	return hash;
}

function formatData(d: unknown): string {
	if (typeof d === 'string') {
		return d;
	} else if (typeof d === 'number') {
		return d.toString();
	} else if (typeof d === 'boolean') {
		return d ? 'true' : 'false';
	} else if (d === null) {
		return 'null';
	} else if (typeof d === 'object') {
		if (Array.isArray(d)) {
			return `[${d.map(formatData).join(',')}]`;
		} else {
			return `{${Object.entries(d)
				.map(([k, v]) => `${k}:${formatData(v)}`)
				.join(',')}}`;
		}
	} else {
		throw new Error(`Unsupported type: ${typeof d}`);
	}
}

Object.assign(window, { formatData });

export function generateSessionHash(
	data: Record<string, unknown>,
	secret: string,
): string {
	const string = formatData(data);
	const hash = createHmac('ripemd160', secret)
		.update(string)
		.digest('hex')
		.substr(0, 32)
		.toUpperCase();

	return hash;
}

export function getUniqueID() {
	const guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
		/[xy]/g,
		function (c) {
			const r = (Math.random() * 16) | 0,
				v = c == 'x' ? r : (r & 0x3) | 0x8;
			return v.toString(16);
		},
	);
	return generateSessionHash(
		{
			guid,
			userAgent: navigator.userAgent,
			time: Date.now(),
		},
		guid,
	);
}
