import * as React from 'react';

import * as styles from './errorPage.module.scss';
import Button from '~src/domains/core/components/Button';

export default function ErrorPage(props: {
	tryAgain?: VoidFunction;
}): JSX.Element {
	const { tryAgain } = props;
	return (
		<div className={styles.root}>
			<h1>Our Apologies</h1>
			<p>There was an unexpected issue.</p>
			{tryAgain ? (
				<Button onClick={tryAgain} primary>
					Try Again
				</Button>
			) : null}
		</div>
	);
}
