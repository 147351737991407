import * as React from 'react';

import ErrorPage from '~src/domains/core/components/ErrorPage';
import Logger from '~src/domains/core/lib/Logger';
import { errorToString } from '~src/utils/errorUtil';

type Props = Record<string, unknown>;
type State = {
	hasError: boolean;
};

export default class ErrorBoundary extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error: unknown, errorInfo: React.ErrorInfo) {
		const str = [
			errorToString(error),
			'Component Stack:',
			errorInfo.componentStack.replace(/^\n/, ''),
		].join('\n');
		Logger.get().error('ErrorBoundary', str);
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return <ErrorPage />;
		}

		return this.props.children;
	}
}
