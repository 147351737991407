import axios from 'axios';

import config from '~src/domains/core/config';

export default axios.create({
	baseURL: config.apiUrl,
	validateStatus: function (status) {
		return status === 200;
	},
});

export const baseURL = config.apiUrl;
