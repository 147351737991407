import _ from 'lodash';

export function safeHasOwnProperty<
	// eslint-disable-next-line @typescript-eslint/ban-types
	Obj extends Record<string, unknown> | {},
	Prop extends string,
>(
	obj: Obj,
	prop: Prop,
): obj is Obj &
	(Obj extends Record<string, unknown>
		? {
				[key in Prop]: Obj[keyof Obj];
		  }
		: {
				[key in Prop]: unknown;
		  }) {
	return obj != null && Object.prototype.hasOwnProperty.call(obj, prop);
}

export function toPercentage<T extends number>(decimal: T): string {
	return `${decimal * 100}%`;
}

export function rafPromise(): Promise<void> {
	return new Promise((fulfill) => requestAnimationFrame(() => fulfill()));
}

export const chopOffDataUrl = (dataUrl: string): string => {
	return dataUrl.substring(dataUrl.indexOf(',') + 1);
};

function isEmptyArray(arr: unknown): arr is [] {
	return Array.isArray(arr) && arr.length === 0;
}

export function defer(): Promise<void>;
export function defer<FN extends (...args: UnsafeAny[]) => UnsafeAny>(
	fn: FN,
	...args: Parameters<FN>
): void;
export function defer(
	...args: [] | [(...args: UnsafeAny[]) => UnsafeAny, ...UnsafeAny[]]
): void | Promise<void> {
	if (isEmptyArray(args)) {
		return new Promise((f) => _.defer(f));
	} else if (args.length > 1) {
		const [fn, ...fnArgs] = args;
		_.defer(fn, ...fnArgs);
	}
}

/**
 * Takes a string, and optional seed, generates a hash.
 *
 * Credits to bryc on [StackOverflow](https://stackoverflow.com/a/52171480/701263)
 */
export function hashCode(str: string, seed = 0): number {
	let h1 = 0xdeadbeef ^ seed,
		h2 = 0x41c6ce57 ^ seed;
	for (let i = 0, ch; i < str.length; i++) {
		ch = str.charCodeAt(i);
		h1 = Math.imul(h1 ^ ch, 2654435761);
		h2 = Math.imul(h2 ^ ch, 1597334677);
	}
	h1 =
		Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^
		Math.imul(h2 ^ (h2 >>> 13), 3266489909);
	h2 =
		Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^
		Math.imul(h1 ^ (h1 >>> 13), 3266489909);
	return 4294967296 * (2097151 & h2) + (h1 >>> 0);
}

export async function preloadVideo(video: string): Promise<string> {
	const res = await fetch(video);
	const blob = await res.blob();
	const url = URL.createObjectURL(blob);
	return url;
}

export async function xpreloadVideo(video: string): Promise<HTMLVideoElement> {
	const res = await fetch(video);
	const blob = await res.blob();
	const url = URL.createObjectURL(blob);

	const container = document.createElement('div');
	Object.assign(container.style, {
		opacity: '0',
		pointerEvents: 'none',
		position: 'absolute',
		zIndex: '-1',
	} as CSSStyleDeclaration);

	document.body.appendChild(container);

	const videoElement = document.createElement('video');

	const loadedPromise = new Promise<void>((fulfill, reject) => {
		const removeAll = () => {
			videoElement.removeEventListener('canplaythrough', f);
			videoElement.removeEventListener('error', r);
		};
		const f = () => {
			removeAll();
			fulfill();
		};

		const r = (e: unknown) => {
			removeAll();
			reject(e);
		};

		videoElement.addEventListener('canplaythrough', f);
		videoElement.addEventListener('error', r);
	});
	container.appendChild(videoElement);

	videoElement.width = 1080;
	videoElement.height = 1920;
	videoElement.style.background = 'transparent';
	videoElement.style.width = '1080px';
	videoElement.style.height = '1920px';
	videoElement.src = url;

	await loadedPromise;

	document.body.removeChild(container);

	return videoElement;
}

export function utcDate(): string {
	const isoDate = new Date().toISOString();

	return `${isoDate.substr(0, 10)} ${isoDate.substr(11, 8)}`;
}

export function createObjectWithValues<K extends string, V>(
	keys: readonly K[],
	value: V,
): {
	[Key in K]: V;
} {
	const ret = keys.reduce((current, next) => {
		return {
			...current,
			[next]: value,
		};
	}, {});

	return ret as UnsafeAny;
}

export function uppercaseFirstLetter(str: string): string {
	return `${str.slice(0, 1).toUpperCase()}${str.slice(1)}`;
}

export function promiseListener<
	T extends {
		addEventListener: (name: K, fn: (value: V) => UnsafeAny) => void;
		removeEventListener: (name: K, fn: (value: V) => UnsafeAny) => void;
	},
	K extends string,
	V,
>(obj: T, key: K): Promise<V> {
	return new Promise((fulfill) => {
		const cb = (value: V) => {
			obj.removeEventListener(key, cb);
			fulfill(value);
		};
		obj.addEventListener(key, cb);
	});
}

type FuncArg<T> = T extends (value: infer V) => UnsafeAny ? V : never;

export function promiseEvent<
	T extends {
		[Key in K]: ((value: UnsafeAny) => UnsafeAny) | null;
	},
	K extends keyof T,
>(obj: T, key: K): Promise<FuncArg<T[K]>> {
	return new Promise<FuncArg<T[K]>>((f) => {
		(obj[key] as unknown) = f;
	});
}

export async function blobToBase64(blob: Blob): Promise<string> {
	const reader = new FileReader();
	const onloadend = promiseEvent(reader, 'onloadend');
	reader.readAsDataURL(blob);
	await onloadend;
	if (typeof reader.result !== 'string') {
		throw new Error('Unable to convert blob to base64');
	}
	return reader.result;
}

export function wait(time: number): Promise<void> {
	return new Promise((f) => setTimeout(f, time));
}

export function unzipObject<T>(obj: T): [(keyof T)[], T[keyof T][]] {
	const keys = [];
	const values = [];
	for (const key in obj) {
		keys.push(key);
		values.push(obj[key]);
	}

	return [keys as UnsafeAny, values as UnsafeAny];
}
type B<V> = {
	[K in keyof V]: Awaited<V[K]> | undefined;
};
export function race<T extends readonly Promise<unknown>[] | []>(
	promises: T,
): Promise<B<T>> {
	return new Promise((resolve, reject) => {
		let resolved = false;
		const res: UnsafeAny = Array(promises.length);
		promises.forEach((promise, index) => {
			promise.then(
				(value) => {
					if (resolved) return;
					resolved = true;
					res[index] = value;
					resolve(res);
				},
				(value) => {
					if (resolved) return;
					resolved = true;
					reject(value);
				},
			);
		});
	});
}

export const query = (() => {
	let searchParams: URLSearchParams | null = null;
	function getParams() {
		if (searchParams == null) {
			searchParams = new URLSearchParams(window.location.search);
		}
		return searchParams;
	}
	const params = new Proxy(
		{},
		{
			get: (searchParams, prop) => {
				if (typeof prop === 'string') {
					return getParams().get(prop);
				}
				return null;
			},
		},
	);

	return params as Record<string, string | null>;
})();

export const lerp = (x: number, y: number, a: number) => x * (1 - a) + y * a;
export const invlerp = (x: number, y: number, a: number) =>
	clamp((a - x) / (y - x));
export const clamp = (a: number, min = 0, max = 1) =>
	Math.min(max, Math.max(min, a));
export const range = (
	x1: number,
	y1: number,
	x2: number,
	y2: number,
	a: number,
) => lerp(x2, y2, invlerp(x1, y1, a));

export function pullFirst<T extends readonly any[]>(
	arr: T,
	predicate: (value: T[number]) => boolean,
): [T[number] | undefined, T] {
	return arr.reduce(
		([found, resArr], next) => {
			if (found == null && predicate(next)) {
				return [next, resArr];
			}
			return [found, [...resArr, next]];
		},
		[undefined as T[number] | undefined, []],
	);
}
