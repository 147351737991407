import { action, autorun, makeObservable, observable } from 'mobx';
import { v4 as uuid } from 'uuid';

import axiosApi from '~src/domains/core/axiosApi';

const clientAndInstanceID = (() => {
	let clientID = localStorage.getItem('clientID');
	if (clientID == null) {
		clientID = uuid().substring(0, 8).toUpperCase();
		localStorage.setItem('clientID', clientID);
	}
	const instanceID = uuid().substring(9, 13).toUpperCase();

	return `${clientID}-${instanceID}`;
})();

type QueueItem = [
	date: string,
	clientAndInstanceID: string,
	severity: string,
	scope: string,
	message: string,
	data?: unknown,
];

export default class Logger {
	private static _instance: Logger;
	private queue: QueueItem[] = [];

	private constructor() {
		makeObservable<Logger, 'queue' | 'processQueue'>(this, {
			queue: observable,
			processQueue: action,
			log: action,
		});
		try {
			const queueJson = localStorage.getItem('queue');
			if (queueJson != null) {
				const queue = JSON.parse(queueJson);
				this.queue = queue;
			}
		} catch (e) {
			void 0;
		}
		this.processQueue();

		autorun(
			() => {
				localStorage.setItem('queue', JSON.stringify(this.queue.slice(-20)));
			},
			{
				delay: 1000,
			},
		);
	}
	private processing = false;
	private async processQueue(): Promise<void> {
		if (this.processing) return;
		this.processing = true;
		try {
			const items = this.queue;
			this.queue = [];
			while (items.length > 0) {
				const item = items.shift();
				if (item == null) continue;
				try {
					await this.processItem(item);
				} catch (e) {
					this.queue.unshift(item);
				}
			}
		} finally {
			this.processing = false;
			setTimeout(() => this.processQueue(), 1000);
		}
	}

	private async processItem(item: QueueItem): Promise<void> {
		await axiosApi.post('logger', {
			date: item[0],
			client: item[1],
			severity: item[2],
			scope: item[3],
			message: item[4],
			data: item[5] ?? null,
		});
	}

	public static get(): Logger {
		if (!Logger._instance) {
			Logger._instance = new Logger();
		}
		return Logger._instance;
	}

	public log(
		severity: string,
		scope: string,
		message: string,
		data?: unknown,
	): void {
		this.queue.push([
			new Date().toISOString(),
			clientAndInstanceID,
			severity,
			scope,
			message,
			data,
		]);
	}

	public debug(scope: string, message: string, data?: unknown): void {
		return this.log('debug', scope, message, data);
	}

	public error(scope: string, message: string, data?: unknown): void {
		return this.log('error', scope, message, data);
	}

	public warning(scope: string, message: string, data?: unknown): void {
		return this.log('warning', scope, message, data);
	}

	public info(scope: string, message: string, data?: unknown): void {
		return this.log('info', scope, message, data);
	}
}
