export default class Cancellable {
	private cancelled = false;

	public cancel(): void {
		this.cancelled = true;
	}

	public isCancelled(): boolean {
		return this.cancelled;
	}

	public wrapPromise<T>(promise: Promise<T>): Promise<T> {
		return new Promise((resolve, reject) => {
			promise.then(
				(value) => {
					if(this.isCancelled())return;
					resolve(value);
				},
				(error) => {
					if(this.isCancelled())return;
					reject(error);
				}
			);
		});
	}
}
