import ReactGA from 'react-ga4';

import * as React from 'react';

import {
	analyticsDimension as apiAnalyticsDimension,
	analyticsEvent as apiAnalyticsEvent,
	getSession,
} from '~src/domains/core/api';
import config from '~src/domains/core/config';
import { useOnMount, useOnUnmount } from '~src/utils/react';

class Analytics<Dimension extends string> {
	trackingId: string;
	dimensionsValue: {
		[key in Dimension]?: string | undefined;
	} = {};
	dimensionsMap: Record<string, string>;

	pageStack: string[] = [];

	constructor(trackingId: string, dimensions: readonly Dimension[]) {
		this.trackingId = trackingId;
		this.dimensionsMap = dimensions.reduce((current, next, index) => {
			return {
				...current,
				[`dimension${index + 1}`]: next,
			};
		}, {} as Record<string, string>);

		ReactGA.initialize(this.trackingId, {
			gaOptions: {},
			gtagOptions: this.config,
		});
	}

	get config() {
		return {
			custom_map: {
				// Google Analytics mutates the object, so we need to clone it
				...this.dimensionsMap,
			},
			send_page_view: false,
			...this.dimensionsValue,
		};
	}

	setDimension(dimension: Dimension, value: string) {
		this.dimensionsValue[dimension] = value;
		this.configure();
		apiAnalyticsDimension(dimension, value);
	}

	configure() {
		ReactGA.gtag('config', this.trackingId, this.config);
	}

	trackPageStack() {
		const lastPage = this.pageStack[this.pageStack.length - 1];
		const pagePath = `/${this.pageStack.join('/')}`;
		ReactGA.send({
			hitType: 'pageview',
			page: pagePath,
			title: lastPage,
		});
		apiAnalyticsEvent('pageView', pagePath);
	}

	trackPage(page: string) {
		this.pageStack = [page];
		this.trackPageStack();
	}

	pushPageStack(page: string) {
		this.pageStack.push(page);
		this.trackPageStack();
	}

	popPageStack() {
		this.pageStack.pop();
		this.trackPageStack();
	}

	event(action: string, value?: number) {
		ReactGA.event({
			category: 'general',
			action: action,
			value: value,
		});
		apiAnalyticsEvent(action, value);
	}
}

let analyticsInstance: null | Analytics<'appType' | 'sessionId' | 'game'> =
	null;

export function initializeAnalytics() {
	if (config.googleAnalytics.enabled === true) {
		analyticsInstance = new Analytics(config.googleAnalytics.trackingId, [
			'appType',
			'sessionId',
			'game',
		]);
		getSession().then(({ session }) => {
			analyticsInstance?.setDimension('sessionId', session);
			analyticsInstance?.setDimension('game', 'none');
		});
	}
}

export function useAnalyticsSetAppType(type: 'mobile' | 'desktop') {
	analyticsInstance?.setDimension('appType', type);
}

export function useAnalyticsSetGame(
	game: 'basketball' | 'football' | 'soccer' | 'none',
) {
	analyticsInstance?.setDimension('game', game);
}

export function useAnalyticsModalTrack(modal: string) {
	useOnMount(() => {
		analyticsInstance?.pushPageStack(modal);
	});

	useOnUnmount(() => {
		analyticsInstance?.popPageStack();
	});
}

export function useAnalyticsPageTrack(page: string) {
	React.useEffect(() => {
		analyticsInstance?.trackPage(page);
	}, [page]);
}

export function analyticsEvent(action: string, value?: number) {
	analyticsInstance?.event(action, value);
}

const wrapWeakMap = new WeakMap<() => unknown, () => unknown>();
export function wrapInAnalyticsEvent<Args extends unknown[], Ret>(
	fn: (...args: Args) => Ret,
	eventAction: string,
): (...args: Args) => Ret {
	let value = wrapWeakMap.get(fn);
	if (value == null) {
		value = (...args: Args) => {
			analyticsEvent(eventAction);
			return fn(...args);
		};
		wrapWeakMap.set(fn, value);
	}

	return value as unknown as (...args: Args) => Ret;
}

const eventFns: Record<string, () => void> = {};
export function createAnalyticsEventFn(eventAction: string) {
	let eventFn = eventFns[eventAction];
	if (eventFn == null) {
		eventFn = () => analyticsEvent(eventAction);
		eventFns[eventAction] = eventFn;
	}
	return eventFn;
}

const analytics = {
	initialize: initializeAnalytics,
	useSetAppType: useAnalyticsSetAppType,
	useModalTrack: useAnalyticsModalTrack,
	usePageTrack: useAnalyticsPageTrack,
	event: analyticsEvent,
	wrapInEvent: wrapInAnalyticsEvent,
	createEventFn: createAnalyticsEventFn,
};

export default analytics;
