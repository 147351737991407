@use 'src/assets/config.scss';

.root {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	h1 {
		font-family: config.$font;
		color: config.$white;
	}

	:global .sk-spinner {
		margin-top: 32px;
		width: 50px;
		height: 50px;
		color: config.$white;
	}
}
